import { clientsApi } from "@/lib/axios";
import { ref } from "vue";

const user = ref<User>();

export default function useUser() {
  const getUser = async () => {
    try {
      const response = await clientsApi.get<UserResponse>("/current-email");
      user.value = mapUser(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addOrgPermission = async (
    orgId: string,
    roleTitle: string,
    purpose: string = ""
  ) => {
    try {
      await clientsApi.post<OrgPermissionResponse>(
        "/add-organisation-permission",
        { organisation_id: orgId, role: roleTitle, purpose: purpose }
      );
      await getUser();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { user, getUser, addOrgPermission };
}

const mapUser = (userData: UserResponse): User => {
  return {
    email: userData.email,
    loginId: userData.login_id,
    isAdmin: userData.is_admin,
    allowedOrgs: userData.allowed_orgs,
    canAccessAllOrgs: userData.can_access_all_organisations,
  } satisfies User;
};

export type User = {
  email: string;
  loginId: string;
  isAdmin: boolean;
  allowedOrgs: string[];
  canAccessAllOrgs: boolean;
};

type UserResponse = {
  email: string;
  login_id: string;
  is_admin: boolean;
  allowed_orgs: string[];
  can_access_all_organisations: boolean;
};

type OrgPermissionResponse = {
  allowed_orgs: string[];
};
