import mixpanel from "mixpanel-browser";
import { createRouter, createWebHistory, type RouterOptions } from "vue-router";
import useUser from "@/composables/useUser";
import { isIAEnabled } from "#composables/useFlagsmith";

const routes: RouterOptions["routes"] = [
  { path: "/login", redirect: "/dashboard" },
  { path: "", redirect: "/dashboard" },
  {
    path: "",
    name: "LoggedInLayout",
    component: () => import("./components/LoggedInLayout.vue"),
    children: [
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("./views/MyRoles.vue"),
      },
      {
        name: "RoleCandidatesDashboard",
        path: "dashboard/role/:role_id",
        component: () => import("./views/RoleCandidates.vue"),
      },
      {
        name: "RoleApplicationDashboard",
        path: "dashboard/candidates/:id",
        component: () => import("./views/RoleApplication.vue"),
      },
      {
        name: "MyAccount",
        path: "account",
        component: () => import("./views/MyAccount.vue"),
      },
      {
        path: "internal",
        meta: {
          adminOnly: true,
        },
        children: [
          {
            path: "dashboard",
            component: () => import("./views/InternalRoles.vue"),
          },
          {
            path: "roles/:role_id",
            component: () =>
              import(
                "./components/internal-assessment/InternalAssessmentLayout.vue"
              ),
            children: [
              {
                path: "assessments",
                beforeEnter: async ({ params }, _, next) => {
                  if (isIAEnabled(params.role_id as string)) next();
                  else next({ name: "Dashboard" });
                },
                component: () =>
                  import("./views/InternalAssessmentDashboard.vue"),
                name: "InternalAssessmentDashboard",
                children: [
                  {
                    path: ":role_application_id",
                    component: () =>
                      import("./views/InternalAssessmentDetails.vue"),
                    name: "InternalAssessmentDetails",
                  },
                ],
              },
              {
                path: "emails",
                component: () => import("./views/InternalAssessmentEmails.vue"),
                name: "InternalAssessmentEmails",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/invite",
    name: "InviteUsers",
    component: () => import("./views/InviteUsers.vue"),
  },
  {
    path: "/role/:role_id",
    name: "RoleCandidatesIframe",
    component: () => import("./views/RoleCandidates.vue"),
  },
  {
    path: "/candidates/:id",
    name: "RoleApplicationIframe",
    component: () => import("./views/RoleApplication.vue"),
  },
  {
    path: "",
    name: "LoggedOutLayout",
    component: () => import("./components/LoggedOutLayout.vue"),
    children: [
      {
        path: "terms-and-conditions",
        name: "TermsAndConditions",
        component: () => import("./views/TermsAndConditions.vue"),
      },
      {
        path: "accept-terms-and-conditions",
        name: "AcceptTermsAndConditions",
        component: () => import("./views/AcceptTermsAndConditions.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/vpn",
    name: "VPNRequired",
    component: () => import("./views/VPNRequired.vue"),
  },
  {
    path: "/invalid-user",
    name: "InvalidUser",
    component: () => import("./views/LoginHasNoUser.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("./views/PageNotFound.vue"),
    name: "PageNotFound",
    props: true,
  },
];

const userIsAdmin = async () => {
  const { user, getUser } = useUser();

  if (!user.value) {
    await getUser();
  }
  return user.value?.isAdmin || false;
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // TODO: remove once we are out of iframe (start)
  const pagesToPrefix = ["RoleCandidatesIframe", "RoleApplicationIframe"];

  if (to.meta.adminOnly) {
    const isAdmin = await userIsAdmin();
    if (isAdmin) {
      return next();
    } else {
      return next({
        name: "Dashboard",
      });
    }
  }

  if (
    from.fullPath.includes("dashboard") &&
    pagesToPrefix.includes(String(to.name))
  ) {
    return next({
      path: "/dashboard" + to.fullPath,
      replace: true,
    });
  }

  // (end)

  mixpanel.track("Page view", {
    name: to.name,
    path: to.path,
  });

  next();
});
export default router;
