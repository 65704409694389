import flagSmith from "flagsmith";
import {
  IAEnabledRoles,
  IAEnabledRejection,
} from "../composables/useFlagsmith";

flagSmith.init({
  environmentID: import.meta.env.VITE_APP_FLAGSMITH_ENVIRONMENT || "",
  cacheFlags: true,
  onChange(oldFlags, params) {
    if (params.flagsChanged) {
      IAEnabledRoles.value = [];
      IAEnabledRejection.value = [];
      if (flagSmith.hasFeature("internal-assessment")) {
        const value = flagSmith.getValue("internal-assessment");
        if (typeof value === "string") {
          IAEnabledRoles.value = JSON.parse(value);
        }
      }
      if (flagSmith.hasFeature("internal-assessment-rejection")) {
        const value = flagSmith.getValue("internal-assessment-rejection");
        if (typeof value === "string") {
          IAEnabledRejection.value = JSON.parse(value);
        }
      }
    }
  },
});
