import * as Sentry from "@sentry/browser";
import flagsmith from "flagsmith";
import mixpanel from "mixpanel-browser";
import useUser from "#composables/useUser";
import checkTsAndCs from "@/lib/checkTsAndCs";

export default async function identifyUser(): Promise<void> {
  try {
    const { user, getUser } = useUser();
    if (!user.value) await getUser();
    if (!user.value) throw new Error("User not found");

    const { loginId, email } = user.value;

    // Terms and conditions
    checkTsAndCs();

    // Flagsmith
    flagsmith.identify(loginId);
    flagsmith.setTrait("email", email);

    // Sentry
    Sentry.setUser({ id: loginId, email });

    // Mixpanel
    mixpanel.identify(loginId);
  } catch (e) {
    console.error(e);
  }
}
