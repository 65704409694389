import { ref } from "vue";

export const IAEnabledRoles = ref<string[]>();
export const IAEnabledRejection = ref<string[]>();

const isDev = () =>
  import.meta.env.VITE_APP_FLAGSMITH_ENVIRONMENT === "dYXUA8spboYZWaPkVSDJ8d";

export const isIAEnabled = (roleId?: string) =>
  !!(roleId && (isDev() || IAEnabledRoles.value?.includes(roleId)));

export const isIARejectionEnabled = (roleId?: string) =>
  !!(roleId && (isDev() || IAEnabledRejection.value?.includes(roleId)));
