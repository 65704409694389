import { onMounted, Ref, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

const connection = new WebSocket(import.meta.env.VITE_APP_WEBSOCKET_HOST);

function isOpen(connection: WebSocket) {
  return connection.readyState === connection.OPEN;
}

export default function useReviewUpdates(role_application_id: string) {
  const reviewUpdates: Ref<string[]> = ref([]);
  const identifier = `{"channel":"ReviewUpdatesChannel", "role_application_id": "${role_application_id}"}`;

  onMounted(() => {
    if (!isOpen(connection)) return;

    connection.send(
      JSON.stringify({
        command: "subscribe",
        identifier,
      })
    );

    connection.onmessage = (event: MessageEvent) => {
      const decision_status = JSON.parse(event.data)?.message?.decision_status;
      if (decision_status) {
        reviewUpdates.value = [...reviewUpdates.value, decision_status];
      }
    };
  });

  onBeforeRouteLeave(() => {
    connection.send(
      JSON.stringify({
        command: "unsubscribe",
        identifier,
      })
    );
  });

  return {
    reviewUpdates,
  };
}
