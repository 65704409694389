import { clientsApi } from "@/lib/axios";
import router from "@/router";

export default async function checkTsAndCs(): Promise<void> {
  clientsApi.get(`/terms-and-conditions`).then(({ data }) => {
    if (Array.isArray(data) && data.length >= 1) {
      router.push({
        name: "AcceptTermsAndConditions",
      });
    }
  });
}
